import styled, { css } from "styled-components";
import { ethSafariColors } from "../../assets/theme/theme.js";

const ButtonWrapper = styled.button`
  border-radius: 0.75rem;

  ${props =>
    props.btnColor
      ? css`
          color: ${props.btnColor};
        `
      : css`
          color: inherit;
        `
  }
  ${props =>
    props.btnBg
      ? css`
          background-color: ${props.btnBg};
        `
      : css`
          background-color: #ddd;
        `
  }

  &:disabled {
    background-color: lightgray; // You can adjust this value as needed
    color: gray; // You can adjust this value as needed
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

const ButtonAltWrapper = styled(ButtonWrapper)`
  * {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: auto auto;
    transition: background-color 0.4s ease-in-out;
  }
  
  color: white;
  border-radius: 3rem;
  padding: 0.25rem 0.5rem;

  ${props =>
    props.fullWidth
      ? css`
          width: 100%;
        `
      : css`
          width: auto;
        `
  }

  ${props =>
    props.btnColor
      ? css`
          color: ${props.btnColor};
        `
      : css`
          color: white;
        `
  }

  ${props =>
    props.btnBg
      ? css`
          background-color: ${props.btnBg};
        `
      : css`
          background-color: ${ethSafariColors.pinkAlt};
        `
  }

  a {
    color: #5A005F;
  }

  &:hover {
    background: white;
    color: #FDA1FD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    background-color: lightgray; // You can adjust this value as needed
    color: gray; // You can adjust this value as needed
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

export { ButtonAltWrapper };
export default ButtonWrapper;
