import {createGlobalStyle} from 'styled-components';
import "./fonts/stylesheet.css";
import "./css/ethsafari.style.css";
import bgDesktop from 'assets/images/bg-desktop.png';
import bgMobile from 'assets/images/bg-mobile.png';
import bgTablet from 'assets/images/bg-tablet.png';


const GlobalStyle = createGlobalStyle`

  ::selection {
    background: #333333;
    color: #ffffff;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:focus {
    outline: none;
  }

  main {
    overflow-x : hidden;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  body, html {

    font-family: Poppins;
    margin: 0px;
    // height: 100%; // set the body height and html height for the bg image to fill the device

  }

  body {
    font-size: 16px;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // font-family: 'gothamrounded-medium', sans-serif;
    font-family: 'Poppins-Regular', sans-serif;
      // color: ${props => props.theme.bodyColor ? props.theme.bodyColor : '#BEABDF'};
    overflow-x: hidden !important;
    height: 100%;
    overflow-y: scroll;
    font-weight: 400;
    margin: 0;
    padding: 0;
    // overflow-y: scroll;
    //background: rgb(252,206,175,1);
    // background: linear-gradient(167deg, rgba(242,133,55,1) 0%, rgba(61,164,239,1) 100%);
    //background: linear-gradient(167deg, rgba(202,71,1,1) 0%, rgba(250,231,1,1) 100%);
    //background: linear-gradient(110deg, rgba(202,71,1,1) 0%, rgba(213,155,0,1) 40%, rgba(95,16,159,1) 100%);
    // Reduced gradient colours to 2 to make the bg look less busy
    // background: linear-gradient(110deg, rgba(213,155,0,1) 0%, rgba(95,16,159,1) 100%);
    // background: linear-gradient(110deg, rgba(255,255,255,1) 0%, rgba(95,16,159,1) 100%);
    // background: linear-gradient(150deg, rgba(0,91,92,1) 40%, rgba(0,0,0,1) 100%);
    // background: linear-gradient(150deg, rgba(0,0,0,1) -20%, rgba(0,91,92,1) 70%);
    // background: linear-gradient(150deg, rgba(0,91,92,1) 30%, rgba(255,247,208,1) 100%);
    // background: linear-gradient(150deg, rgba(0,91,92,1) 30%, rgba(255,247,208,1) 90%, rgba(255,255,255,1) 100%);
    // background: linear-gradient(150deg, rgba(0,91,92,1) 30%, rgba(255,255,255,1) 85%, rgba(255,247,208,1) 100%);
    // background: linear-gradient(150deg, rgba(192,252,248,1) 30%, rgba(255,246,255,1) 85%, rgba(255,255,255,1) 100%);
    // background: linear-gradient(120deg, rgba(192,252,248,1) 30%, rgba(255,246,255,1) 85%, rgba(255,255,255,1) 100%);
    // background: linear-gradient(150deg, rgba(192,252,248,1) 50%, rgba(90,0,95,1) 130%);

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    // background: linear-gradient(-45deg, #FDA1FD, #5A005F, #007675, #FFFADE);
    background-size: 1000% 500%;
    animation: gradient 15s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  // @keyframes gradient {
  //   0% {
  //     background-position: 0% 50%;
  //   }
  //   35% {
  //     background-position: 75% 50%;
  //   }
  //   50% {
  //     background-position: 100% 50%;
  //   }
  //   67% {
  //     background-position: 25% 50%;
  //   }
  //   100% {
  //     background-position: 0% 50%;
  //   }
  //   //
  //   //background-repeat: no-repeat;
  //   //// background-size: cover;
  //   //background-position: center center;
  // }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // font-family: 'gothamrounded-medium', sans-serif;
    font-family: 'Poppins-Regular', sans-serif;
    color: ${props => props.theme.headingColor ? props.theme.headingColor : '#FFFFFF'};
  }

  p {
    font-family: sans-serif;
    margin: 0 0 15px 0;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.headingColor};
  }

  img {
    max-width: 100%;
  }


  section {
    position: relative;
  }

  .text-center {
    text-align: center;
  }

  button, .btn {
    display: inline-block;
    min-width: 170px;
    height: 60px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 3rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: "Poppins-SemiBold";

  }


  // /* 
  //   ##Device = Desktops
  //   ##Screen = 1281px to higher resolution desktops
  // */

  // @media (min-width: 1281px) {

  //   /* CSS */
  //   body {
  //     background-image: url(${bgDesktop});
  //   }

  // }

  // /* 
  //   ##Device = Laptops, Desktops
  //   ##Screen = B/w 1025px to 1280px
  // */

  // @media (min-width: 1025px) and (max-width: 1280px) {

  //   /* CSS */
  //   body {
  //     background-image: url(${bgDesktop});
  //   }

  // }

  // /* 
  //   ##Device = Tablets, Ipads (portrait)
  //   ##Screen = B/w 768px to 1024px
  // */

  // @media (min-width: 768px) and (max-width: 1024px) {

  //   /* CSS */
  //   body {
  //     background-image: url(${bgTablet});
  //   }

  // }

  // /* 
  //   ##Device = Tablets, Ipads (landscape)
  //   ##Screen = B/w 768px to 1024px
  // */

  // @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  //   /* CSS */
  //   body {
  //     background-image: url(${bgDesktop});
  //     background-repeat: no-repeat;
  //     background-position: center center;
  //   }
  // }

  // /* 
  //   ##Device = Low Resolution Tablets, Mobiles (Landscape)
  //   ##Screen = B/w 481px to 767px
  // */

  // @media (min-width: 481px) and (max-width: 767px) {

  //   /* CSS */
  //   body {
  //     background-image: url(${bgDesktop});
  //     background-repeat: no-repeat;
  //     background-position: center center;
  //   }

  // }

  // /* 
  //   ##Device = Most of the Smartphones Mobiles (Portrait)
  //   ##Screen = B/w 320px to 479px
  // */

  // @media (min-width: 320px) and (max-width: 480px) {

  //   /* CSS */
  //   body {
  //     background-image: url(${bgMobile});
  //     background-repeat: no-repeat;
  //     background-position: center center;
  //   }

  
`

export default GlobalStyle
