import React from 'react';

import { 
    FaTwitter,
    FaTumblr,
    FaGooglePlusG,
    FaFacebookF,
    FaLinkedinIn,
    FaSlack,
    FaMediumM,
    FaTelegramPlane,
    FaSnapchatGhost,
    FaPinterestP,
    FaYoutube,
    FaDiscord
  } from "react-icons/fa";
import FaArtstorm from "../../theme/CustomIcons/FaArtStorm";

  export default {
    links : [
        {
            icon: <FaTwitter />,
            url: "https://twitter.com/EthSafari"
        },
        {
            icon: <FaDiscord />,
            url: "https://discord.gg/CkMF5Ff83f"
        },
        {
            icon: <FaArtstorm className='icon-custom' />,
            url: "https://artstorm.ethsafari.xyz"
        },
        {
            icon: <FaTelegramPlane />,
            url: "https://t.me/ethsafari"
        },
        {
            icon: <FaLinkedinIn />,
            url: "https://www.linkedin.com/company/ethsafari/"
        },
        // {
        //     icon: <FaGooglePlusG />,
        //     url: "#"
        // },
        // {
        //     icon: <FaFacebookF />,
        //     url: "#"
        // },
        // {
        //     icon: <FaLinkedinIn />,
        //     url: "#"
        // },
        // {
        //     icon: <FaSlack />,
        //     url: "#"
        // },
        // {
        //     icon: <FaMediumM />,
        //     url: "#"
        // },
        // {
        //     icon: <FaTelegramPlane />,
        //     url: "#"
        // },
        // {
        //     icon: <FaSnapchatGhost />,
        //     url: "#"
        // },
        // {
        //     icon: <FaPinterestP />,
        //     url: "#"
        // },
        // {
        //     icon: <FaYoutube />,
        //     url: "#"
        // }
    ]
  }