import React from "react";
import PropTypes from "prop-types";

import ButtonWrapper, {ButtonAltWrapper} from './button.style';

const Button = ({ children, ...props }) => {
  const AllClasses = ["btn"];
  if (props.className) {
    AllClasses.push(props.className);
  }

  return (
      <ButtonAltWrapper {...props} className={AllClasses.join(" ")}>
        {children}
      </ButtonAltWrapper>)
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  as: PropTypes.oneOf([ "button", "a", "span", "div" ]) //--
};

export default Button;
