import styled from "styled-components";

const RowWrapper = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  &.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  &.no-gutters > .col,
  &.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  background: ${props => props.bgImg ? `url(${props.bgImg})` : "transparent"};
`;
export default RowWrapper;
