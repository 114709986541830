import React from "react"
import { Link } from "gatsby"
import { Link as OnepageLink } from "react-scroll"

import { List, ListItem } from "reusecore/List"
import Button from "../../../reusecore/Button/index.js"
import { ethSafariColors } from "../../../assets/theme/theme.js"

const ScrollspyMenu = ({ menuItems, id, ...props }) => {
  const addAllClasses = [""]
  const pageLinks = [
    "/speakers",
    "https://artstorm.ethsafari.xyz/",
    "/agenda",
    "/ethsafari-2023",
  ]
  if (props.className) {
    addAllClasses.push(props.className)
  }
  return (
    <List className={addAllClasses.join(" ")}>
      {menuItems.map((menu, index) => (
        <ListItem
          key={index}
          className={
            menu.subItems !== undefined ? "nav-item has-dropdown" : "nav-item"
          }
        >
          {id === "agenda" ? (
            <Link
              activeClass="nav-active"
              to={menu.path}
              spy={true}
              smooth={true}
              offset={parseInt(menu.offset)}
              duration={700}
            >
              {menu.name}
            </Link>
          ) : !pageLinks.includes(menu.path) ? (
            <OnepageLink
              activeClass="nav-active"
              to={menu.path}
              spy={true}
              smooth={true}
              offset={parseInt(menu.offset)}
              duration={700}
            >
              {menu.name}
            </OnepageLink>
          ) : (
            <Link
              activeClass="nav-active"
              to={menu.path}
              spy={true}
              smooth={true}
              offset={parseInt(menu.offset)}
              duration={700}
            >
              {menu.name}
            </Link>
          )}
          {menu.subItems !== undefined && (
            <List key={index} className="dropdown">
              {menu.subItems.map((subItem, i) => (
                <ListItem key={i}>
                  <OnepageLink
                    activeClass="nav-active"
                    to={subItem.path}
                    style={{ color: ethSafariColors.darkAlt }}
                    spy={true}
                    smooth={true}
                    duration={700}
                  >
                    {subItem.name}
                  </OnepageLink>
                  {/*<Link to={subItem.path} >{subItem.name}</Link>*/}
                </ListItem>
              ))}
            </List>
          )}
        </ListItem>
      ))}
      {/*<ListItem>*/}
      {/*    <a href="https://artstorm.ethsafari.xyz" target="_blank" className="nav-item">artSTORM</a>*/}
      {/*</ListItem>*/}
      {/*<ListItem>*/}
      {/*  <Button className="nav__button">Join In</Button>*/}
      {/*</ListItem>*/}
    </List>
  )
}

export default ScrollspyMenu
