import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { FaBars } from "react-icons/fa";
import { Container } from "reusecore/Layout";
import logowide from "assets/images/ethsafari-logo-icon.svg";
import Box from "reusecore/Box";
import Text from "reusecore/Text";
import gem from '../../assets/images/standard.svg'
import microphone from '../../assets/images/microphone-pink.svg'
import Data from "./utility/data.js";
import ScrollspyMenu from "./utility/ScrollspyMenu";
import NavigationWrap from "./navigation.style";
import {NavIconWrapper} from "../../assets/theme/CustomIcons/CustomIcon.style.js";
import {PopupButton} from "@typeform/embed-react";


const Navigation = ({ id }) => {
  const [expand, setExpand] = useState(false);
  let data;
  if(id === 'agenda'){
    data = [...Data.agendaMenuItems]
  } else {
    data = [...Data.menuItems]
  }
  useEffect(() => {
    const header = document.getElementById("navbar");

    const handleScroll = window.addEventListener("scroll", (eventDetails) => {
      if (window.scrollY >= 50) {
        if(!header.classList.contains('scrolled')){
          header.classList.add('scrolled');
        }
      } else {
        if(header.classList.contains('scrolled')){
          header.classList.remove("scrolled");
        }
      }
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //[expand, scroll]
  return (
    <NavigationWrap
      id="navbar"
      className={`nav-block ethsafari-navbar`}
    >
      <Container>
        <div className="navbar-wrap">
          <Link to="/" className="logo"> 
              <img src={logowide} alt="prime app landing" /> 
          </Link>
       <Box className="ethsafari-actions-container">
            <PopupButton id="uCjGV3CQ" as={Box} className="banner-alt-btn">
              <Box>
                <a className={"btn btn-fill-alternate"}>
                  <Text as="span" style={{
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>Sponsor
                    <NavIconWrapper className="nav-icon"><img
                        src={gem} alt=""/></NavIconWrapper> </Text>
                </a>
              </Box>
            </PopupButton> 

            <PopupButton id={'jV6E8gux'} as={Box} className="banner-alt-btn">
             <Box>
               <a className={"btn btn-fill-alternate"}>
                 <Text as="span" style={{ paddingTop:0, paddingLeft: 0, paddingRight: 0, display: 'flex', justifyContent: 'flex-start'}}>Speak
                   <NavIconWrapper  className="nav-icon"  style={{ padding:'0 0.15rem', marginTop: '0.2rem'}}>
                     <img src={microphone} alt=""/></NavIconWrapper> </Text>
               </a>
             </Box>
            </PopupButton>
          </Box>
          <nav className="nav">
            <FaBars
                className="mobile-menu-icon"
                onClick={() => setExpand(!expand)}
            />
            <ScrollspyMenu
                id={id}
                className={`collapsed ${expand ? "is-expanded" : ""}`}
                menuItems={data}
            />
          </nav>
          <div className="background-nav"></div>
        </div>
      </Container>
    </NavigationWrap>
  );
};

export default Navigation;
