import styled from "styled-components";
import footerBg from "assets/images/footer-bg.png";

const CustomIconWrapper = styled.div`
  width: 2.4rem;

  svg {
    fill: #beabdf;
    color: #beabdf;

    &:hover {
      color: #fff;
      fill: #fff;
    }
  }

`;

const SmallIconWrapper = styled(CustomIconWrapper)`
  min-width: 2.4rem;
  min-height: 2rem;
`;

const NavIconWrapper = styled(CustomIconWrapper)`
  width: 0.9rem;
  height: 0.6rem;
`;

export {SmallIconWrapper, NavIconWrapper};

export default CustomIconWrapper;
