

const ethSafariColors = {
  dark:'#5A005F',
  darkAlt:'#5E1C9C',
  light:'#23A6D5',
  pink:'#E73C7E',
  pinkAlt: '#f569fc',
  orange:'#ee7752',
  red:'#E73C7E',
  blue:'#23a6d5',
  blueDarkTone:'#23a6d5',
  lightAlt:'#BEABDF',
    whiteTone: '#ffffff',
    whiteTone2: 'rgba(255,255,255,0.8)',
    whiteTone3: 'rgba(255,255,255,0.65)',
    blackTone: '#000',
    blackToneAlt: '#2B1242',
};

const theme = {
    ethSafariColors,
    black: '#333333',
    dark: ethSafariColors.dark,
    darkAlt: ethSafariColors.darkAlt,
    blackToneAlt: ethSafariColors.blackToneAlt,
    white: ethSafariColors.whiteTone,
    primaryColor: ethSafariColors.light,
    primaryHoverColor: '#E73C7E',
    secondaryColor: '#383d41',
    headingColor: ethSafariColors.whiteTone,
    bodyColor: ethSafariColors.whiteTone,
    btnColor: ethSafariColors.whiteTone,
    linkColor: 'rgba(255,255,255,0.2)',
    NavBG: 'transparent',
    NavBGSolid: ethSafariColors.dark,
    menuHoverColor: ethSafariColors.lightAlt
};

export { ethSafariColors };
export default theme;

