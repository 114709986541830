import React from 'react';
import CustomIconWrapper from "./CustomIcon.style.js";
const FaArtstorm = ({className}) => {
    return (
        <CustomIconWrapper className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 618.03 545.52">
                <defs>
                {/*    <style>{`*/}
                {/*.cls-1, #SvgjsG1308, #Layer_2 {fill: rgb(190, 171, 223); }*/}
                {/* #Layer_2:hover, #Layer_2:active, #Layer_2:focus{fill: #fff;}*/}
                {/*`}</style>*/}
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="white_bg_2" data-name="white bg 2">
                        <g id="SvgjsG1308">
                            <polygon className="cls-1"
                                     points="82.4 290.94 82.4 254.58 41.2 254.58 41.2 290.94 41.2 327.31 82.4 327.31 82.4 290.94"/>
                            <polygon className="cls-1"
                                     points="123.61 218.21 123.61 181.84 82.4 181.84 82.4 218.21 82.4 254.58 123.61 254.58 123.61 218.21"/>
                            <polygon className="cls-1"
                                     points="288.42 109.11 288.42 72.74 247.21 72.74 206.01 72.74 206.01 109.11 247.21 109.11 288.42 109.11"/>
                            <polygon className="cls-1"
                                     points="370.82 72.74 370.82 36.37 329.62 36.37 288.42 36.37 288.42 72.74 329.62 72.74 370.82 72.74"/>
                            <polygon className="cls-1"
                                     points="576.83 0 535.63 0 494.43 0 453.22 0 412.02 0 370.82 0 370.82 36.37 412.02 36.37 453.22 36.37 494.43 36.37 535.63 36.37 576.83 36.37 576.83 72.74 576.83 109.11 535.63 109.11 494.43 109.11 453.22 109.11 412.02 109.11 370.82 109.11 370.82 145.47 412.02 145.47 453.22 145.47 494.43 145.47 535.63 145.47 576.83 145.47 576.83 181.84 576.83 218.21 535.63 218.21 494.43 218.21 453.22 218.21 412.02 218.21 370.82 218.21 370.82 254.58 412.02 254.58 453.22 254.58 494.43 254.58 535.63 254.58 576.83 254.58 576.83 290.94 576.83 327.31 535.63 327.31 494.43 327.31 453.22 327.31 453.22 363.68 494.43 363.68 535.63 363.68 576.83 363.68 618.03 363.68 618.03 327.31 618.03 290.94 618.03 254.58 618.03 218.21 618.03 181.84 618.03 145.47 618.03 109.11 618.03 72.74 618.03 36.37 618.03 0 576.83 0"/>
                            <rect className="cls-1" x="123.61" y="145.47" width="41.2" height="36.37"/>
                            <rect className="cls-1" x="164.81" y="109.1" width="41.2" height="36.37"/>
                            <polygon className="cls-1"
                                     points="206.01 290.94 206.01 254.58 164.81 254.58 164.81 290.94 164.81 327.31 206.01 327.31 206.01 290.94"/>
                            <polygon className="cls-1"
                                     points="288.42 145.47 288.42 181.84 329.62 181.84 370.82 181.84 370.82 145.47 329.62 145.47 288.42 145.47"/>
                            <rect className="cls-1" x="206.01" y="218.21" width="41.2" height="36.37"/>
                            <rect className="cls-1" x="247.21" y="181.84" width="41.2" height="36.37"/>
                            <rect className="cls-1" x="288.41" y="290.95" width="41.2" height="36.37"/>
                            <rect className="cls-1" x="329.62" y="254.58" width="41.2" height="36.37"/>
                            <polygon className="cls-1"
                                     points="370.82 436.42 370.82 472.79 370.82 509.15 329.62 509.15 288.42 509.15 288.42 472.79 288.42 436.42 288.42 400.05 288.42 363.68 288.42 327.31 247.21 327.31 247.21 363.68 247.21 400.05 247.21 436.42 247.21 472.79 247.21 509.15 206.01 509.15 164.81 509.15 164.81 472.79 164.81 436.42 164.81 400.05 164.81 363.68 164.81 327.31 123.61 327.31 123.61 363.68 123.61 400.05 123.61 436.42 123.61 472.79 123.61 509.15 82.4 509.15 41.2 509.15 41.2 472.79 41.2 436.42 41.2 400.05 41.2 363.68 41.2 327.31 0 327.31 0 363.68 0 400.05 0 436.42 0 472.79 0 509.15 0 545.52 41.2 545.52 82.4 545.52 123.61 545.52 164.81 545.52 206.01 545.52 247.21 545.52 288.42 545.52 329.62 545.52 370.82 545.52 412.02 545.52 412.02 509.15 412.02 472.79 412.02 436.42 412.02 400.05 370.82 400.05 370.82 436.42"/>
                            <rect className="cls-1" x="412.02" y="363.68" width="41.2" height="36.37"/>
                        </g>
                    </g>
                </g>
            </svg>
        </CustomIconWrapper>
    );
}

export default FaArtstorm;