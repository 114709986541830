import styled from "styled-components";

const SubscribeWrapper = styled.div`
  padding: 25px 0;

  .auto-margin {
    margin : auto auto;
  }

  .subscribe_Img{
    min-width: 36rem;
    animation: levitate 10s ease-in-out infinite;

  }

  .subscribe-box-wrapper {
    .title__wrapper {
      text-align: center;
    }

    .subscribe-box-bg {
      position: relative;
      padding: 35px 0;

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 43px;
        background: transparent;
        background: linear-gradient(
          94deg,
          rgba(253,210, 253, 1) 0%,
          rgba(35,183, 197, 1) 100%
        );
        content: "";
        opacity: 0;
      }
    }
    .form-box {
      position: relative;
      margin-top: 20px;
      background: transparent;
      opacity: 0.9;
      button {
        position: absolute;
        right: 10px;
        top: 10px;
        font-family: 'Poppins-Medium';
        color: #000;
        width: 220px;
        border-radius: 10px;
        border: none;
        transition: all 0.3s ease-in;
        background: transparent;
        
        background: rgba(35,183, 197, 0.7);
        &:hover{
          background: transparent;
          font-family: 'Poppins-Bold';
          background: rgba(253,210, 253, 1);
        }
      }
      .input__wrapper {
        input {
          height: 80px;
          padding-left: 12px;
          border-radius: 10px;
          
          font-family: 'Poppins-Medium';

          &::placeholder {
            color: rgba(179,114, 179, 1);
            font-family: 'Poppins-Medium';
          }
        }
      }
    }
  }
  @media only screen and (max-width: 912px) {
    .subscribe-box-wrapper{
      .subscribe-box-bg{
        padding: 10px 3px;
      }
    } 

    .subscribe_Img{
    min-width: 30rem;
  }
  }
  @media only screen and (max-width: 568px) {
    .subscribe-box-wrapper{
      .subscribe-box-bg{
        padding: 30px 3px;
      }
      .form-box{
        text-align: center;
        margin-top: 25px;
        button{
          position: relative;
          right: 0;
          top: 0;
          margin: 30px auto 0 auto;
        }
      } 
    } 

    .subscribe_Img{
      min-width: 20rem;
    }
  }
  @media only screen and (max-width: 480px) {

    .subscribe_Img{
      min-width: 15rem;
    }
    .subscribe-box-wrapper{
      .subscribe-box-bg {
         padding: 10px 4px;
      }
    } 
    .heading{
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 375px) {
    .heading{
      font-size: 25px;
      line-height: 35px;
    }
  }


@keyframes levitate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
`;
   
export default SubscribeWrapper;
