import React, {useState, useRef} from "react"
import jsonp from "jsonp";
import lgImg2 from 'assets/images/2023/FGP_1_4.png';
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"
import Button from "reusecore/Button"
import Input from "reusecore/Form/Input"
import { SectionTitle } from "reusecore/SectionTitle"
import SubscribeWrapper from "./subscribe.style"
import { ToastProvider, useToasts } from 'react-toast-notifications';

const Subscribe = () => {
  
  const {addToast } = useToasts();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(undefined)


  const onSubmit = e => {
    e.preventDefault();
    
    console.log('email  ', email)
    // return;
    setLoading(true);
    const notif = {
      icon: 'message',
      title: 'Thank You!',
      message: 'Awesome, check your email to continue with the latest of ETHSafari 2023!',
    };

    const errNotif = {
      icon: 'message',
      title: 'Something went wrong',
      message: 'Couldn\'t sign you up :( please try again shortly.',
    };
    const url = 'https://xyz.us11.list-manage.com/subscribe/post-json?u=63864163b925b3a22159d0b59&amp;id=14a7512011&amp;f_id=0096a8e0f0';
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
        const { msg, result } = data
        // do something with response
        console.log(data);
        if(msg){
          addToast(notif.message, { appearance: 'success' });
        } else {
          addToast(errNotif.message, { appearance: 'error' });
        }
        
      setLoading(false);
    });
  };
  return (
    <SubscribeWrapper id="contact">
      <Box className="subscribe-box-wrapper">
        <Container>
          <Row>
            <Col className="col-12">
              <Box className="subscribe-box-bg">
                <Row>
                <Col className="lg-2 xs-12">
                  <Image className="subscribe_Img" src={lgImg2} alt="Karibu ETHSafari 2023"/>
                </Col>
                <Col className="lg-4 offset-lg-3 xs-12 auto-margin">
                    <SectionTitle>
                      <Heading FontFamily={'Poppins-SemiBold'} > Don’t Miss Out! </Heading>
                      <Text FontSize={"1.3rem"} FontFamily={'Poppins' }>Stay updated with the ETHSafari newsletter.</Text>
                    </SectionTitle>
                  </Col>
                </Row>
                <Row>
                  <Col className="lg-8 offset-lg-2 xs-12">
                    <Box className="form-box">
                      <form onSubmit={onSubmit}>
                        <Input
                          name="email"
                          value={email}
                          placeholder="fomo@ethsafari.xyz"
                          onChange={e => setEmail(e.target.value)}
                          type="email"  
                          className="required email" 
                          id="mce-EMAIL" 
                          required
                        />
                        <Button disabled={loading} type="submit"> {loading ? "..." : "Subscribe" } </Button>
                      </form>
                      
                    </Box>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </SubscribeWrapper>
  )
}

export default Subscribe
