const Data = {
  menuItemsOld: [
    {
      name: "Home",
      path: "/",
      subItems: [
        {
          name: "Home Two",
          path: "/home-two",
        },
        {
          name: "Home Three",
          path: "/home-three",
        },
        {
          name: "Home Slider",
          path: "/home-slider",
        },
      ],
    },
    {
      name: "About",
      path: "about",
      offset: "-100",
    },
    {
      name: "Service",
      path: "service",
      offset: "-120",
    },
    {
      name: "Token",
      path: "token",
      offset: "-100",
    },

    {
      name: "Pages",
      path: "/blog-grid",
      subItems: [
        {
          name: "Blog Grid",
          path: "/blog-grid",
        },
        {
          name: "Blog List",
          path: "/blog-list",
        },
        {
          name: "Blog Full",
          path: "/blog-full",
        },
        {
          name: "Blog Single",
          path: "/blog-single",
        },
      ],
    },
    {
      name: "FAQ",
      path: "faq",
      offset: "-60",
    },
    {
      name: "Contact",
      path: "contact",
      offset: "-70",
    },
  ],
  agendaMenuItems: [
    {
      name: "ETHSafari Guide",
      path: "/",
    },
    {
      name: "artSTORM",
      path: "https://artstorm.ethsafari.xyz/",
    },
  ],
  menuItems: [
    // {
    //   name: "Guide",
    //   path: "agenda",
    // },
    // {
    //   name: "Schedule",
    //   path: "sched",
    // },
    // {
    //   name: "Agenda",
    //   path: "/agenda",
    // },
    // {
    //   name: "Speakers",
    //   path: "/speakers",
    // },
    {
      name: "Partners",
      path: "partners",
    },
    // {
    //   name: "Travel Guide",
    //   path: "/blog-list",
    // },
    // {
    //   name: "artSTORM",
    //   path: "https://aragon.org/",
    // },
    {
      name: "ETHSafari-2023",
      path: "/ethsafari-2023",
    },

    // {
    //   name: "More",
    //   subItems: [
    //     {
    //       name: "Juicebox",
    //       path: "safariOnJuicebox",
    //     },
    //     {
    //       name: "FAQ",
    //       path: "faq",
    //     },

    //   ],
    // }
  ],
}
export default Data
