import styled from "styled-components";
import footerBg from "assets/images/footer-bg.png";

const FooterWrapper = styled.div`
  .footer-content-wrapper {
    background: transparent;
  }

  a {
    &:hover {
      color: #fff;
    }
  }


  .footer-widgets {
    margin-bottom: 3rem;

    a {
      color: #beabdf;
      font-size: 18px;
      font-weight: 300;
      line-height: 38px;
    }

    img {
      width: 180px;
      margin-bottom: 27px;
    }

    &.company-desc {
      .text {
        line-height: 26px;
      }
    }

    &.address {
      font-size: 18px;

      p {
        line-height: 38px;
      }
    }

    .heading {
      font-size: 24px;
      font-weight: 500;
      color: #beabdf;
      margin-bottom: 23px;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      font-size: 18px;

      a {
        display: flex;
        align-items: center;

        svg {
          margin-right: 15px;
        }
      }
    }
  }

  .footer-social-link-item {
    margin: 0 0.5rem;
  }


  .footer-social-links, .icon-custom {
    padding: 0.5rem 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      font-size: 36px;
      font-weight: 700;
      color: #beabdf;

      &:hover {
        color: #fff;
      }
    }
  }
  
  .icon-custom {
    padding: 0.45rem 0.65rem 0.5rem 0;
    min-width: 3rem;
    border-top: none;
  }

  .copyright-text {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 10px 0px;
  }

  .copyright-text-item {
    display: flex;
  }

  @media only screen and (max-width: 480px) {
    .footer-social-links {
      justify-content: center;
      flex-wrap: wrap;

      a {
        max-width: 16%;
        flex: 0 0 16%;
        margin: 15px 0;
      }
    }

    .copyright-text {
      justify-content: center;
      flex-direction: column-reverse;
    }

    .copyright-text-item {
      display: flex;
      justify-content: center;
    }
  }
`;

export default FooterWrapper;
