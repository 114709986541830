import styled from 'styled-components';
import {ethSafariColors} from "../../assets/theme/theme.js";

const NavigationWrap =  styled.header`
    background: ${props => props.theme.NavBG};
    position: sticky;
    width: 100%;
    z-index: 9999;
    top: 0;
    transition: all 0.3s ease 0s;
    border-bottom: 1px solid transparent;
    // font-family: Poppins-Regular;
    font-family: Poppins-SemiBold;
  .navbar-wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //border-bottom: 2px solid #FAE701; remove bottom border
  }
  
  .nav-icon {
    //padding: 0 0.1rem;
    margin: 0.15rem 0.2rem 0 0.7rem;
  }
  .mobile-menu-icon {
    display: none;
    color: #1D316C;
    font-size: 24px;
  }
  .nav__button{
    width: 170px;
    height: 2.7rem;
    background: rgba(90,0,95,0.4);
    border-radius: 2rem;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    color: ${props => props.theme.white};
    transition: all 0.3s ease-in;
    &:hover,
    &:focus{
      outline: none;
      border: none;
      background: rgba(90,0,95,0.95);
    }
  }

  .ethsafari-actions-container{
      display: flex;
      justify-content: space-between;
    }
  .banner-btn {
    margin-top: 8px;
    margin-bottom: 8px;
    a {
      width: 170px;
      height: 40px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      padding: 2px 22px;
      display: flex;
      align-items: row;
      justify-content: row;
      border: none;
      border-radius: 10px;

      svg {
        margin-right: 10px;
      }
    }

    .btn-fill {
      transition: all 0.3s ease-in;
      background: rgb(255, 138, 0);
      background: linear-gradient(
        93deg,
        rgba(37, 42, 213, 1) 0%,
        rgba(122, 49, 222, 1) 52%,
        rgba(196, 56, 231, 1) 100%
      );
      &:hover {
        background: linear-gradient(
          93deg,
          rgba(196, 56, 231, 1) 0%,
          rgba(122, 49, 222, 1) 52%,
          rgba(37, 42, 213, 1) 100%
        );
      }
    }
  }

  .banner-btn, .banner-alt-btn {
    margin: 0 0.4rem 0 0.05rem;

    a {
      max-width: 3rem;
      max-height: 2.2rem;
      // width: 100px;
      font-size: 1rem;
      font-weight: bold;
      color: ${ethSafariColors.darkAlt};
      // padding-left: 5px;
      // padding-right: 8px;
      // padding: 0px 0px;
      display: flex;
      margin: 0.5rem 0;
      // align-items: row;
      // justify-content: row;
      // flex: 1;
      // justifyContent: center;
      // flex-direction: row;
      // align-items: center;
      // justify-content: space-evenly;
      border: none;

      svg {
        margin-right: 10px;
      }
    }

    .image-btn {
      // animation: pulse 4s infinite;
      // animation: fadeIn 40s;
      // animation: shakeX 10s infinite;
      animation: jello 5s infinite;
    }

    .btn-fill {
      transition: all 0.3s ease-in;
      background: rgb(255, 138, 0);
      background: linear-gradient(93deg,
        // rgba(255,113,255,1) 0%,
        // rgba(255, 166, 0, 1) 52%,
        // rgba(153, 207, 28, 1) 100%
      rgba(3, 3, 3, 1) 0%,
        // rgba(255, 166, 0, 1) 52%,
      rgba(251, 251, 251, 1) 100%);

      &:hover {
        background: linear-gradient(93deg,
        rgba(251, 251, 251, 1) 0%,
        rgba(3, 3, 3, 1) 100%
          // rgba(153, 207, 28, 1) 0%,
          // rgba(255, 166, 0, 1) 52%,
          // rgba(255, 113, 255, 1) 100%
        );
      }
    }

    .btn-fill-alternate, .btn-fill-alternate-dark {
      transition: all 0.3s ease-in;
      // background: #5A005F;
      // background: #FDA1FD;
      background: white;
      // color: #000000;
      // color: #FDA1FD;
      color: #5A005F;
      border-radius: 3rem;
      font-family: 'Poppins-SemiBold';
      justify-content: center;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &:hover {
        // background: white;
        background: #5A005F;
        // background: #FDA1FD;
        // color: #005B5C;
        // color: #FDA1FD;
        color: ${ethSafariColors.whiteTone};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  /*Drop down style*/ 
  .dropdown{
    background: #fff;
    visibility: hidden;
    opacity: 0;
    min-width: 200px;
    border: 1px solid #f5f5f5;
    position: absolute;
    margin-top: 1rem;
    left: 20px;
    padding: 15px 0px;
    display: none;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
  }
  ul li:hover > ul,
  ul li ul:hover {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    display: block;
  }
  
  nav {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
    }
    li{
        position: relative;
        padding: 28px 0px 28px 40px;
        .dropdown{
          li{
            padding: 0;
            a{
              color: rgba(255,255,255,0.4);
              display: block;
              padding: 5px 15px;
              &:hover{
                color: ${props => props.theme.menuHoverColor ? props.theme.menuHoverColor : '#1F2AD5'} !important;
              }
              &:before{
                content: none;
              }
            }
          }
        }
      
      a, .nav-active{
        position: relative;
        //color: #fff;
        font-size: 1rem;
        transition: 450ms all;
        padding-bottom: 5px;
        cursor: pointer;
        &:before{
          content: '';
          position: absolute;
          left : 0;
          bottom:0;
          width: 50%;
          height: 2px;
          opacity: 0;
          background: ${props => props.theme.menuHoverColor};
          transition: 450ms all;
        }
        &:hover{
          color: ${props => props.theme.menuHoverColor};
          &:before{
            opacity: 1;
          }
        }
      }
    }
  }
  .logo{
    margin-top: 8px;
    transition: all 0.8s cubic-bezier(0.3, 0.8, 0.2, 1) 0s;
    img{
      width: 50px;
      //opacity: 0;
    }
  }
  &.scrolled{ 
    // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background: rgba(190, 171, 223, 0.95);
    color: ${(props) => props.theme.dark};
    .nav{
      li{
        a{ 
          &:hover{
            color: ${props => props.theme.dark ? props.theme.dark : ''};
          }
        }
      }
       li{
         .dropdown{
           li{
             padding: 0;
           }
         }
       }
     }
  }


  @media only screen and (max-width: 912px) {
    height: auto;
    &.scrolled{
        padding: 10px 0px;
        border-color: transparent;
        .nav .collapsed {
          li{
            a{
              display: block;
            }
          }
          li+li{
            padding-left: 0;
          }
        } 
    }
    .has-dropdown{
      a{
        position: relative;
        &:before{
          content: '';
          
        }
      }
    }
    .dropdown{
      position: relative;
      left: 0;
      margin-top: 0;
      box-shadow: none;
    }
    .navbar-wrap{
        border-color: transparent;
        display: block;
        position: relative;
    }
    .logo {
      width: 100%;
      display: block;
      margin: 0 0 0 -25px;
      img{
        width: 50px;
      }
    }
    .mobile-menu-icon {
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 3px;
      cursor: pointer;
    }
    .nav{
        width: 100%;
        .mobile-menu-icon{
          color: white;
        }
      .collapsed {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        transition: 450ms all ease-in-out;
        overflow: hidden;
        max-height: 0;

        &.is-expanded {
          padding-top: 15px;
          overflow-y: auto;
          max-height: 250px; /* approximate max height */
        }
        li {
          padding: 0;
          margin: 0px 0px;
          width: 100%;
          .dropdown{
            li{
              a{
                color: #1D316C;
              }
            }
          }
          a{
            padding: 8px 10px;
            display: block;
            color: white;
            &:before{
              content: none;
            }
          }
        }
      }
    }
  }
  
  .scrolled .nav-active li a {
    color: ${(props) => props.theme.dark};
  }

  @media only screen and (max-width: 375px) {
    .ethsafari-actions-container{
      display: block;
    }

    .banner-btn, .banner-alt-btn {
    margin-top: 0px;
    a {
      max-width: 100%;
      min-width: 2.5rem;
      font-size: 1rem;
      font-weight: bold;
      padding-left: 5px;
      padding-right: 8px;
      margin: 0.25rem 0;

      svg {
        margin-right: 3px;
      }
    }
  }




`

export default NavigationWrap;