import React from 'react';
import styled from 'styled-components';

import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageWrapper = styled.img`
    /* width: ${props => props.width? props.width : '100%'}; */
    height: auto;
    display: block;

    
    min-width: ${props => props.bgImage?.styleOpts?.minWidthLg};  

@media only screen and (max-width: 1024px) {
  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthMd};;
  }
}

@media only screen and (max-width: 912px) {

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthMd};;
  }
}

@media only screen and (max-width: 768px) {

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthSm};;
  }
  }

@media only screen and (width: 768px) {

.banner_landingImg {
  min-width: ${props => props.bgImage?.styleOpts?.minWidthSm};;
}
}



@media only screen and (width: 1024px) {
.banner_landingImg {
  min-width: ${props => props.bgImage?.styleOpts?.minWidthMd};;
}}

@media only screen and (max-width: 480px) {
  .btn-text {
    font-size: 0.85rem
  }

  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthXs};;
  }
}


@media only screen and (max-width: 375px) {
  .banner_landingImg {
    min-width: ${props => props.bgImage?.styleOpts?.minWidthXs};
  }
}

`


const Image = ({ src, alt, ...props}) => {
    const AllClasses = ["image"];
    if(props.className){
        AllClasses.push(props.className)
    };
    return(
        <LazyLoadImage as={ImageWrapper} src={src} alt={alt}  {...props} className={AllClasses.join(" ")}/>
    )
}

export default Image;